export default (() => {
  if (process.server) {
    return;
  }

  const referrer = document?.referrer;

  if (referrer && !referrer.includes('synergy.ru') && !referrer.includes('syndev.ru')) {
    localStorage.setItem('visitSource', referrer);
  }

  function getUtmParameter(sParam) {
    const url = window.location.search.substring(1);
    const urlVariables = url.split('&');

    for (let i = 0; i < urlVariables.length; i += 1) {
      const variables = urlVariables[i].split('=');

      if (variables[0] === sParam) {
        return variables[1];
      }
    }

    return false;
  }

  const utmSource = getUtmParameter('utm_source');

  if (utmSource) {
    localStorage.setItem('visitUtmSource', utmSource);
  }
});
